@font-face {
  font-family: cardo;
  src: url(Cardo-Regular.ttf);
}

.bottom-bar {
  position: fixed;
  margin-left: 16px;
  bottom: 34px;
  left: 0px;
  width: calc(100% - 32px);
  background-color: white;
  border-radius: 8px;
}
.thumbnail {
  background-size: cover;
  width: calc(33% - 26px);
  height: 100px;
  border-radius: 12px;
  margin: 4px;
  display: inline-block;
}
.hero-image {
  background-size: cover;
  width: 100%;
  height: 200px;
  border-radius: 12px;
  margin: 4px;
  display: inline-block;
}
.gallery-image {
  background-size: cover;
  width: 100%;
  height: 180px;
  border-radius: 4px;
  margin: 4px;
  display: inline-block;
}
.block {
  padding-top: 8px;
  min-height: 18px;
}
.block-input-div {
}
.block-input {
  border: 1px solid #ccc;
  padding: 0px;
  height: 32px;
  padding-left: 8px;
  border-radius: 8px;
}
.block-subtext {
  font-size: small;
  color: gray;
  font-style: italic;
}

.picker-date-name {
  text-align: center;
  padding-top: 5px;
  display: inline-block;
  width: 14.25%;
  font-weight: bold;
}
.picker-date-day {
  text-align: center;
  padding-top: 15px;
  height: 50px;
  display: inline-block;
  width: 14.25%;
  font-weight: bold;
  margin-top: 5px;
}

.picker-selected {
  background-color: aqua;
  border-radius: 30px;
}

.timeline-number {
  text-align: center;
  padding-top: 4px;
  font-size: 26px;
}
.date-box {
  margin: 2px;
  border-radius: 8px;
  padding: 3px;
  background-color: #eee;

  font-size: 12px;
  text-align: center;
  width: calc(49% - 4px);
  display: inline-block;
}
.date-filter {
  width: 100%;
  margin-bottom: 4px;

  top: 0;
  left: 0;
  background-color: white;
  z-index: 9;
}
.lines {
  border: 2px solid black;
  margin-bottom: 6px;
}
.custom-file-upload {
  display: inline-block;
  padding: 3px 3px;
  cursor: pointer;
  width: 68px;
  height: 68px;
  border-radius: 16px;
  background-color: #f4f5f8;
  border: 4px solid black;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.custom-group-file-upload {
  display: inline-block;
  padding: 3px 3px;
  cursor: pointer;
  width: 100%;
  height: 68px;
  margin-top: 12px;
  border-radius: 16px;
  background-color: #f4f5f8;
  border: 4px solid black;
  box-shadow: -2px 4px 0px 0px #005e5a;
}
input[type="file"] {
  display: none;
}

.EmojiPickerReact .Flex.FlexRow {
  flex-direction: row;
  display: none;
}

.bottom-camera {
  color: black;
  position: fixed;
  bottom: 26px;
  left: calc(50% - 35px);
}
._is6ww {
  width: unset !important;
  border-radius: 8px;
}

.time-box {
  width: calc(50% - 10px);
  display: inline-block;
  border-radius: 8px;
  border: #ccc 3px solid;
  padding: 5px;
  margin: 5px;
  text-align: center;
  font-weight: bold;
  font-size: small;
}
.with-chat {
  margin-right: 90vw;
}

body {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

ion-fab-button {
  --border-radius: 10px;
  --border-color: black;
  --border-width: 4px;
  --border-style: solid;
  --box-shadow: -2px 2px 10px 0px rgba(0, 0, 0, 0);
}

ion-button {
  --border-radius: 12px;
  --border-color: #000;
  --border-style: solid;
  --border-width: 2px;

  --box-shadow: -2px 4px 0px 0px #005e5a;
}

.login-input {
  caret-color: transparent;
}

.mapboxgl-marker {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  background-color: #005e5a !important;
  border: 2px solid black;
  box-shadow: -2px 4px 0px 0px #005e5a;
}
.gm-style-moc {
  height: unset !important;
}

ion-toggle {
  height: 25px;
  width: 55px;

  padding: 1px;

  --background: #ddd;
  --background-checked: #ddd;

  --handle-background: #a0a0a0;
  --handle-background-checked: #95c34e;

  --handle-width: 25px;
  --handle-height: 25px;
  --handle-max-height: auto;
  --handle-spacing: 0px;

  --handle-border-radius: 50%;
  --handle-box-shadow: none;

  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
  contain: none;
}

.cs-message-input__content-editor-wrapper {
  border-radius: 8px;
  border: 2px solid black;
  padding: 0px;
  padding-left: 8px;
  border-radius: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: rgb(255, 255, 255) !important;
}

.cs-message-input__content-editor {
  background-color: rgb(255, 255, 255) !important;
}
.cs-button--send {
  color: #000 !important;
  font-size: x-large !important;
}
.cs-message.cs-message--incoming.cs-message--first .cs-message__content {
  border-radius: 8px;
  border: 2px solid black;
  padding: 5px;
  padding-left: 8px;
  border-radius: 8px !important;
  box-shadow: -2px 4px 0px 0px #f9b200;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: rgb(255, 255, 255) !important;
}
.cs-message.cs-message--outgoing.cs-message--first .cs-message__content {
  border-radius: 8px;
  border: 2px solid black;
  padding: 5px;
  padding-left: 8px;
  border-radius: 8px !important;
  box-shadow: 2px 4px 0px 0px #03d9cf;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: rgb(255, 255, 255) !important;
}
.cs-chat-container {
  border: "0px solid #000 !important";
}

@media only screen and (min-width: 900px) {
  ion-app {
    max-width: 900px;
    margin: auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.95);
  }
  body {
    background-color: rgb(210, 210, 210);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }
}
.rhap_container {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}

.spectrum--medium_9e130c,
.spectrum--large_9e130c {
  border-radius: 50%;
  --rm-audio-player-interface-container: transparent !important;
  --rm-audio-player-volume-fill: rgba(0, 0, 0, 0.5);
  --rm-audio-player-volume-track: #ababab;
  --rm-audio-player-track-current-time: #0072f5;
  --rm-audio-player-track-duration: #8c8c8c;
  --rm-audio-player-progress-bar: #0072f5;
  --rm-audio-player-waveform-cursor: var(--spectrum-global-color-gray-800);
  --rm-audio-player-waveform-background: transparent !important;
  --rm-audio-player-waveform-bar: var(--rm-audio-player-progress-bar);
  --rm-audio-player-sortable-list: var(--spectrum-global-color-gray-200);
  --rm-audio-player-sortable-list-button-active: #0072f5;
  --rm-audio-player-selected-list-item-background: transparent !important;
}
